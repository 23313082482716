





































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import './scss/FiltersSelected.scss';

@Component({
    name: 'FiltersSelected',
    components: {
        BaseCheckbox: () => import('@components/FiltersSelected/FiltersSelected.vue'),
    }
})

export default class FiltersCheckboxes extends Vue {
    @Prop({}) selectedFilters!: any[];

    showSection(item) {
        if (item.type === 'checkboxes' || item.type === 'select') {
            return !!item.selected.length;
        }
        if (item.type === 'dateRange') {
            return item.datesRange.dateFrom || item.datesRange.dateTo;
        }
        return false;
    }

    labelSelectedItem(val, ratio) {
        if (ratio && ratio.length) {
            return ratio.filter(item => item.value === val)[0].label;
        }
        return val;
    }
}
